import {expressions} from '../../survey/validators';
import {en_3rd_agreement_texts, p, rtl} from '../../components/survey/review_fields';

import env from "../../env";

const required = env.ENDPOINT !== 'local';

const your_signature = [
  'أقر أنا الموقع ادناه بصحة المعلومات والبيانات والوثائق المزودة من قبل المدين ومن قبلي أنا الكفيل ( { first_name } ) لشركة صندوق المرأة للتمويل الأصغر وأنها مطابقة لمعلوماتي وبياناتي الشخصية وقد تم تعبئتها وأنا بكامل أهليتي المعتبرة قانونياً وأنني بذلك أكون مسؤول مسؤولية كاملة عن كفالتي وكذلك فإنني اتفهم تماماً بأنه في حال عدم قيام المدين بالسداد أن أقوم بسداد كامل قيمة القرض وفي حال عدم قيامي بالسداد فإن للشركة الحق في اتخاذ الإجراءات القانونية بحقي دون أي اعتراض.',
];

const your_signature_agreement = (name) => {
  return `أقر أنا الموقع ادناه بصحة المعلومات والبيانات والوثائق المزودة من قبل المدين ومن قبلي أنا الكفيل ${name} لشركة صندوق المرأة للتمويل الأصغر وأنها مطابقة لمعلوماتي وبياناتي الشخصية وقد تم تعبئتها وأنا بكامل أهليتي المعتبرة قانونياً وأنني بذلك أكون مسؤول مسؤولية كاملة عن كفالتي وكذلك فإنني اتفهم تماماً بأنه في حال عدم قيام المدين بالسداد أن أقوم بسداد كامل قيمة القرض وفي حال عدم قيامي بالسداد فإن للشركة الحق في اتخاذ الإجراءات القانونية بحقي دون أي اعتراض.`;
};

export const agreement = (lt, name, prefix, loan_purpose) => {
  console.log(loan_purpose);
  if (loan_purpose === 'education') {
    console.log('education');
    return `${prefix}

      Loan Amount (JOD): ${lt.amount}
      [x] I agree

      Loan Terms (Months): ${lt.repayment_period}
      [x] I agree

      ${en_3rd_agreement_texts}
      [x] I agree 

      ${your_signature_agreement(name)}
      [x] I agree 
      `;
  } else {
    console.log('business');
    return `${prefix}
      Loan Amount (JOD): ${lt.amount}
      Loan Terms (Months): ${lt.repayment_period}
      Expected Date to Receive the Loan (if granted): ${
        lt.start_loan_date_translated
      }
      Expected Monthly Administrative Fees (%): ${
        lt.monthly_administrative_fees * 100
      }%
      Expected Total Administrative Fees (JOD): ${lt.total_fees}
      Expected Monthly Installment including Fees (JOD): ${
        lt.monthly_installment
      }
      Expected First Installment Date: ${lt.start_repayment_date_translated}
      
      Your Signature:
      ${your_signature_agreement(name)}
      [x] I agree `;
  }
};

const i_agree = {
  title: ' ',
  type: 'checkbox',
  name: 'agree',
  isRequired: true,
  choices: [
    {
      value:
        'Guarantor has agreed to loan amount, loan term and total fees for the { loan_purpose } flow.',
      text: {
        en: 'I agree',
        ar: 'أنا أوافق',
      },
    },
  ],
  validators: [],
};

export const guarantor = {
  showProgressBar: 'top',
  showQuestionNumbers: 'off',
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: { en: 'Submit information', ar: 'أرسل البيانات' },

  questionStartIndex: 1,
  requiredText: '',

  pages: [
    // Page 1: Acceptance
    {
      title: { en: 'Guarantor acceptance', ar: 'Guarantor acceptance' },
      name: 'guarantor_refused_page',
      elements: [
        {
          title: {
            en:
              'Welcome {first_name}, you are invited to be the guarantor of {user_first_name} for a loan amount of {amount} JOD. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.',
            ar:
              'مرحبًا {first_name} تمت دعوتك لتكون الكفيل لمقدّم الطلب {user_first_name} للحصول على قرض بمبلغ {amount} دينار أردني. لتلبية هذه الدعوة، سوف يطلب منك بعض المعلومات عن نفسك. يرجى تحديد ما إذا كانت الدعوة مقبولة من جانبك أم لا. إذا كان لديك أي أسئلة ، يرجى الاتصال بنا.',
          },
          name: 'guarantor_refused',
          type: 'radiogroup',
          isRequired: required,
          choices: [
            {
              value: false,
              text: {
                en: 'I accept',
                ar: 'أوافق',
              },
            },
            {
              value: true,
              text: {
                en: 'I don’t accept',
                ar: 'لا أوافق',
              },
            },
          ],
        },
      ],
    },
    // Page 2: Information
    {
      title: { en: 'Guarantor Information', ar: 'معلومات الكفيل' },
      name: 'personal',
      elements: [
        {
          title: { en: 'Guarantor Full Name *', ar: 'اسم الكفيل الرباعي *' },
          name: 'last_name',
          type: 'text',
          isRequired: required,
        },
        {
          title: {
            en:
              'Guarantor Mobile No.',
            ar:
              'رقم الهاتف للكفيل *',
          },
          name: 'phone_number',
          type: 'phone',
          validators: [
            expressions.phone_en('phone'),
            expressions.phone_length('phone'),
          ],
          isRequired: required,
        },
        {
          name: "date_of_birth",
          type: "text",
          inputType: "date",
          isRequired: required,
          title: {
            en: "Date of Birth *",
            ar: "تاريخ الميلاد *"
          }
        },
        {
          title: {
            en: 'Where do you live?',
            ar: 'أين تعيش؟',
          },
          name: 'panel_home',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: "home_street",
              type: "text",
              isRequired: required,
              title: {
                en: "Street Name *",
                ar: "اسم الشارع *"
              }
            },
            {
              name: "home_building_number",
              type: "text",
              isRequired: required,
              title: {
                en: "Building No. *",
                ar: "رقم البناية *"
              }
            },
            {
              name: "home_house_number",
              type: "text",
              isRequired: required,
              title: {
                en: "House No. *",
                ar: "رقم المنزل *"
              }
            },
            {
              name: "home_city",
              type: "text",
              isRequired: required,
              title: {
                en: "City *",
                ar: "المدينة *"
              }
            },
            {
              name: "home_addres_details",
              type: "comment",
              isRequired: required,
              title: {
                en: "Address Details *",
                ar: "تفاصيل عنوان المنزل *"
              }
            },
          ],
        },
      ],
    },
    // Page 3: Identification
    {
      name: "identification",
      title: {
        en: "Identification Information",
        ar: "معلومات التعريف",
      },
      elements: [
        {
          name: "nationality",
          type: "dropdown",
          isRequired: required,
          storeOthersAsComment: false,
          hasOther: true,
          title: {
            en: "Nationality ",
            ar: "الجنسية ",
          },
          choices: [
            {
              value: "jordan",
              text: {
                en: "Jordanian",
                ar: "أردني",
              },
            },
            {
              value: "palestinian",
              text: {
                en: "Palestinian",
                ar: "فلسطيني",
              },
            },
            {
              value: "syrian",
              text: {
                en: "Syrian",
                ar: "سوري",
              },
            },
            {
              value: "egyptian",
              text: {
                en: "Egyptian",
                ar: "مصرية",
              },
            },
            {
              value: "iraqi",
              text: {
                en: "Iraqi",
                ar: "عراقية",
              },
            },
          ],
        },
        {
          title: {
            en: "What identification paper do you have?",
            ar: "نوع الوثيقة "
          },
          name: "id_type",
          type: "dropdown",
          visibleIf: "false",
          isRequired: required,
          choices: [
            {
              value: "id_card",
              text: { en: "National ID Card", ar: "هوية مدنية" },
            },
            {
              value: "passport",
              text: { en: "Passport", ar: "جواز سفر" },
            },
          ],
        },
        {
          title: {
            en: "Place of Issue",
            ar: "مكان الإصدار",
          },
          name: "custom_text_2",
          type: "text",
          isRequired: required,
        },
        {
          type: "text",
          name: "id_number",
          title: {
            en: "National ID / Card ID / Document No. ",
            ar: "رقم الوثيقة ",
          },
          isRequired: required,
        },
        {
          title: { en: "Front Image (Attachment) ", ar: "الصورة الأمامية " },
          description: {
            en: "If you cannot find the document now, you can close this form and come back later. ",
            ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا ",
          },
          name: "id_file",
          visibleIf: "{id_type} notempty",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          title: {
            en: "Back Image (Attachment) ",
            ar: "الصورة الخلفية ",
          },
          description: {
            en: "If you cannot find the document now, you can close this form and come back later.",
            ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
          },
          name: "id_file_back",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          name: "id_avatar",
          type: "panel",
          visibleIf: "{id_file} notempty",
          innerIndent: 1,
          isRequired: required,
          title: {
            en: "Please verify that this card belongs to you ",
            ar: "من فضلك قم بالتأكيد بأن هذه البطاقة تعود لك ",
          },
          elements: [
            {
              name: "id_avatar_desc",
              type: "html",
              description:
                "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
              html: {
                en: "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
                ar: "يرجى التحقق من معلومات التعريف المقدمة أعلاه عن طريق تحميل صورة لنفسك تحمل معلومة التعريف المقدمة.",
              },
            },
            {
              name: "id_avatar_desc_picture",
              type: "html",
              html: `<img alt="example" src='${require("../../assets/images/example_card.png")}' width='200'/>`,
            },
            {
              name: "id_file_with_avatar",
              type: "file_uploader",
              accept: "image/*,application/pdf",
              isRequired: required,
              title: {
                en: "Identification Picture ",
                ar: "صورة التعريف ",
              },
            },
          ],
        },
        {
          name: "id_question_mother",
          type: "text",
          isRequired: required,
          title: {
            en: "Mother's Name ",
            ar: "اسم الأم ",
          },
        },
        {
          name: "id_question_nickname",
          type: "text",
          isRequired: required,
          title: {
            en: "Surname ",
            ar: "الكنية ",
          },
        },
        {
          name: "id_question_birthplace",
          type: "text",
          isRequired: required,
          title: {
            en: "Place of Birth ",
            ar: "مكان الولادة ",
          },
        },
        {
          title: {
            en: "Civil Record No.",
            ar: "رقم القيد المدني ",
          },
          name: "custom_text_3",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "Date of Issue",
            ar: "تاريخ الإصدار",
          },
          name: "custom_text_4",
          type: "text",
          inputType: "date",
          isRequired: required,
        },
        {
          title: {
            en: "Expiry Date",
            ar: "تاريخ الانتهاء",
          },
          name: "custom_text_5",
          type: "text",
          inputType: "date",
          isRequired: required,
        },
      ],
    },
    // Page 4: Marital
    {
      name: "guarantor_marital_status",
      title: {
        en: "Family Information",
        ar: "معلومات العائلة"
      },
      elements: [
        {
          name: "marital",
          type: "radiogroup",
          isRequired: required,
          title: {
            en: "What is your marital status? *",
            ar: "ما هي حالتك الاجتماعية؟ *"
          },
          choices: [
            {
              value: "single",
              text: {
                en: "Single",
                ar: "أعزب"
              }
            },
            {
              value: "married",
              text: {
                en: "Married",
                ar: "متزوج"
              }
            },
            {
              value: "divorced",
              text: {
                en: "Divorced",
                ar: "مطلق"
              }
            },
            {
              value: "widowed",
              text: {
                en: "Widowed",
                ar: "أرمل"
              }
            }
          ]
        },
        {
          name: "family_member_amount",
          type: "nouislider",
          isrequired: required,
          step: 1,
          rangeMin: 0,
          rangeMax: 20,
          visibleIf:
            "{marital} == 'married' or {marital} == 'widowed' or {marital} == 'divorced'",
          title: {
            en: "No. of Family Members *",
            ar: "عدد أفراد العائلة *"
          }
        },
        {
          name: "full_name_spouse",
          type: "text",
          isRequired: required,
          visibleIf: "{marital} == 'married' or {marital} == 'widowed'",
          title: {
            en: "Spouse Full Name *",
            ar: "اسم الزوج / الزوجة الرباعي *"
          }
        },
        {
          name: "nationality_spouse",
          type: "text",
          isRequired: required,
          storeOthersAsComment: false,
          hasOther: true,
          visibleIf: "{marital} == 'married'",
          title: {
            en: "Spouse Nationality *",
            ar: "جنسية الزوج / الزوجة *"
          }
        },
        {
          name: "id_number_spouse",
          type: "text",
          isRequired: required,
          visibleIf: "{marital} == 'married'",
          title: {
            en: "Spouse National ID / Document No. *",
            ar: "الرقم الوطني / رقم الوثيقة للزوج / الزوجة *"
          }
        },
        {
          name: "phone_number_spouse",
          type: "text",
          isRequired: required,
          visibleIf: "{marital} == 'married'",
          title: {
            en: "Spouse Mobile No. *",
            ar: "رقم هاتف الزوج / الزوجة *"
          }
        },
        {
          name: "current_job_spouse",
          type: "text",
          isRequired: required,
          visibleIf: "{marital} == 'married'",
          title: {
            en: "Spouse Job *",
            ar: "وظيفة الزوج / الزوجة *"
          }
        },
        {
          name: "job_address_spouse",
          type: "comment",
          isRequired: required,
          visibleIf: "{marital} == 'married'",
          title: {
            en: "Spouse Work Address *",
            ar: "عنوان عمل الزوج / الزوجة *"
          }
        },
      ]
    },
    // Page 5: Bank Account
    {
      name: "bank_account",
      title: {
        en: "Bank Account",
        ar: "حساب البنك"
      },
      elements: [
        {
          title: {
            en: "Do you have a bank account? *",
            ar: "هل لديك حساب فى البنك؟ *"
          },
          name: "bank_account", // Not the best name, but it's already in use in the database.
          type: "radiogroup",
          isRequired: required,
          choices: [
            { value: "yes", text: { en: "Yes", ar: "نعم" } },
            { value: "no", text: { en: "No", ar: "لا" } }
          ]
        },
        {
          name: "id_question_bankaccount",
          type: "text",
          isRequired: required,
          visibleIf: "{bank_account} == 'yes'",
          title: {
            en: "Bank Account No. *",
            ar: "رقم الحساب البنكي *"
          }
        },
        {
          name: "id_question_bank_name",
          type: "text",
          isRequired: required,
          visibleIf: "{bank_account} == 'yes'",
          title: {
            en: "Bank Name *",
            ar: "اسم البنك *"
          }
        },
        {
          name: "id_question_bankaccount_type",
          type: "text",
          isRequired: required,
          visibleIf: "{bank_account} == 'yes'",
          title: {
            en: "Bank Account Type *",
            ar: "نوع الحساب البنكي *"
          }
        },
        {
          title: {
            en:
              "Bank Account Balance as of today (JOD) *",
            ar: "رصيد الحساب البنكي اعتبارًا من اليوم (دينار أردني) *"
          },
          name: "assets_bank",
          type: "nouislider",
          step: 10,
          rangeMin: 0,
          rangeMaxStart: 500,
          maxStep: 500,
          rangeMax: 25000,
          visibleIf: "{bank_account} == 'yes'",
          isrequired: required,
        },
        {
          type: "panel",
          title: {
            en: "Please upload the last three months bank statements (at least) *",
            ar: "يرجى تحميل كشف حساب بنكي لآخر ثلاثة أشهر (على الأقل) *"
          },
          description: {
            en:
              "If you cannot find the document now, you can close this form and come back later.",
            ar:
              "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا"
          },
          visibleIf: "{bank_account} == 'yes'",
          elements: [1, 2, 3].map(n => ({
            title: {
              en: `Bank statement ${n} *`,
              ar: "كشف حساب بنكي *"
            },
            name: `bank_statement_${n}`,
            type: "file_uploader",
            accept: "image/*,application/pdf",
            isRequired: required
          }))
        },
        {
          name: "optional_bank_attachments",
          valueName: "optional_bank_attachments",
          type: "matrixdynamic",
          minRowCount: 0,
          rowCount: 0,
          isRequired: false,
          visibleIf: "{bank_account} == 'yes'",
          title: {
            en: "Please upload any other attachments you would like to share with us.",
            ar: "يرجى تحميل أي مرفقات أخرى ترغب في مشاركتها معنا."
          },
          addRowText: {
            en: "Add",
            ar: "إضافة"
          },
          columns: [
            {
              name: "optional_attachment_title",
              isRequired: required,
              cellType: "text",
              inputType: "string",
              title: {
                en: "Title *",
                ar: "العنوان *"
              }
            },
            {
              name: "optional_attachment_file",
              cellType: "file_uploader",
              accept: "image/*,application/pdf",
              isRequired: required,
              title: {
                en: "Attachment *",
                ar: "المرفق *"
              }
            }
          ]
        },
      ]
    },
    // Page 6: Income Iformation
    {
      title: { en: 'Income information', ar: 'معلومات الإيرادات / الدخل' },
      name: 'guarantor_income_info',
      elements: [
        {
          title: {
            en: 'Income Details',
            ar: 'تفاصيل الإيرادات / الدخل',
          },
          name: 'income',
          valueName: 'income',
          type: 'matrixdynamic',
          minRowCount: 1,
          rowCount: 1,
          isRequired: required,
          addRowText: { en: 'Add', ar: 'إضافة' },
          columns: [
            {
              title: { en: 'Description', ar: 'الوصف' },
              name: 'guarantor_income_title',
              isRequired: true,
              cellType: 'text',
              inputType: 'string',
            },
            {
              title: { en: 'Amount', ar: 'مبلغ' },
              name: 'guarantor_income_description',
              isRequired: true,
              cellType: 'text',
              inputType: 'number',
            },
            {
              title: {
                en: 'Attachment',
                ar: 'المرفق',
              },
              name: 'guarantor_income_evidence',
              cellType: 'file_uploader',
              accept: 'image/*,application/pdf',
              isRequired: true,
            },
          ],
        },
      ],
    },
    // Page 7: Job Information
    {
      title: {
        en: 'Job Information',
        ar: 'معلومات الوظيفة',
      },
      name: 'job',
      elements: [
        {
          title: {
            en: "Do you have a current job? *",
            ar: "هل لديك وظيفة حالية؟ *",
          },
          description: {
            en: "Either part-time or full-time, apart from our current project.",
            ar: "إما عمل بدوام جزئي أو بدوام، كامل، بعيداً عن مشروعك الحالي/ فكرة مشروعك الحالية؟",
          },
          name: "has_job",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: true,
              text: {
                en: "Yes",
                ar: "نعم",
              },
            },
            {
              value: false,
              text: {
                en: "No",
                ar: "لا",
              },
            },
          ],
        },
        {
          title: {
            en: 'Could you share details of your current job with us?',
            ar: 'هل يمكنك مشاركتنا بتفاصيل عملك الحالي؟',
          },
          name: 'current_jobs',
          valueName: 'current_jobs',
          type: 'panel',
          visibleIf: '{has_job} == true',
          innerIndent: 1,
          elements: [
            {
              title: {
                en: "Work Phone No. *",
                ar: "رقم هاتف العمل *",
              },
              name: "current_job_phone",
              type: "phone",
              isRequired: required,
              validators: [
                expressions.phone_en("current_job_phone"),
                expressions.phone_length("current_job_phone"),
              ],
            },
            {
              title: {
                en: "Position *",
                ar: "المنصب *",
              },
              name: "current_job_position",
              isRequired: required,
              type: "text",
            },
            {
              title: {
                en: "Company Name *",
                ar: "اسم الشركة *",
              },
              name: "current_job_company",
              isRequired: required,
              type: "text",
            },
            {
              title: {
                en: "Company Address *",
                ar: "عنوان الشركة *",
              },
              name: "current_job_address",
              isRequired: required,
              type: "comment",
            },
            {
              title: {
                en: "Weekly Working Hours *",
                ar: "ساعات العمل الأسبوعية *",
              },
              name: "current_job_hours",
              isRequired: required,
              type: "text",
              inputType: "number",
            },
            {
              title: {
                en: "Monthly Salary (JOD) *",
                ar: "الراتب الشهري (دينار أردني) *",
              },
              name: "current_job_salary",
              isRequired: required,
              type: "text",
              inputType: "number",
            },
            {
              title: {
                en: "Please attach your salary slip.",
                ar: "يرجى إرفاق قسيمة راتبك."
              },
              description: {
                en: "If you cannot find the document now, you can close this form and come back later.",
                ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
              },
              name: "current_job_salary_slip",
              type: "file_uploader",
              accept: "image/*,application/pdf",
              isRequired: false,
            },
            {
              title: {
                en: "Job Description *",
                ar: "المسمى الوظيفي *",
              },
              name: "current_job_description",
              isRequired: required,
              type: "comment",
            },
          ],
        },
      ],
    },
    // Page 8: Review and Sign
    {
      title: {
        en: 'Review the loan and sign below',
        ar: 'قم بقراءة العقد كاملاً ومن ثم قم بالتوقيع أدناه',
      },
      name: 'loan',
      elements: [
        {
          type: "panel",
          visibleIf: "{loan_purpose} == 'education'",
          name: 'review_education',
          elements: [
            {
              type: 'panel',
              elements:  [
                {
                  title: {
                    en: "Loan Amount (JOD)",
                    ar: "مبلغ القرض (دينار أردني)"
                  },
                  type: "expression",
                  expression: "{amount}", // Floating Point Proof
                },
        
                { ...i_agree, name: 'agree1' }
              ]
            },        
            {
              type: 'panel',
              elements:  [
                {
                  title: {
                    en: "Loan Term (Months)",
                    ar: "فترة السداد (أشهر)"
                  },
                  type: "expression",
                  expression: "{repayment_period}", // Floating Point Proof
                },        
                { ...i_agree, name: 'agree2' }
              ]
            },        
            {
              type: 'panel',
              elements:  [
                {
                  type: 'html',
                  name: 'removeThisName',
                  title: '',
                  html: {
                    ar: [
                      'سيتم احتساب الرسوم الإدارية وإضافتها إلى مبلغ القرض.',
                      'سيتم احتساب الطوابع والضرائب ودفعها من قبل مقدم الطلب على الدفعة الأولى المجدولة.',
                      'سيتم إدراج الرسوم الإدارية ورسوم الطوابع والضرائب في العقد الذي يجب توقيعه من جانبك وفقًا لذلك.'
                    ].map(p).join(''),
                    en: en_3rd_agreement_texts.map(p).join('')
                  }
                },        
                { ...i_agree, name: 'agree3' }
              ]
            },
          ]
        },
        {
          visibleIf: "{loan_purpose} == 'business'",
          title: { en: 'The loan terms:', ar: 'شروط العقد' },
          name: 'terms',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: 'Loan Amount (JOD)',
                ar: 'قيمة القرض (دينار أردني)',
              },
              type: 'expression',
              expression: '{amount}', // Floating Point Proof
            },
            {
              title: {
                en: 'Loan Terms (Months)',
                ar: 'فترة السداد (أشهر)',
              },
              type: 'expression',
              expression: '{repayment_period}', // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Date to Receive the Loan (if granted)',
                ar: 'التاريخ المتوقع للحصول على القرض (في حال تم منحه)',
              },
              type: 'expression',
              name: 'start_loan_date_translated',
              expression: "getLoanTerm({loan_terms}, 'start_loan_date_translated')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Administrative Fees (%) (Final calculated numbers will be included in the contract)',
                ar: 'الرسوم الإدارية الشهرية المتوقعة (%) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "(getLoanTerm({loan_terms}, 'monthly_administrative_fees') * 100) + '%'" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Total Administrative Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'إجمالي الرسوم الإدارية المتوقعة (دينار أردني) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_fees')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Installment including Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'القسط الشهري المتوقع بما يشمل الرسوم (دينار) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_monthly')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected First Installment Date (Final calculated date will be included in the contract)',
                ar: 'تاريخ القسط الأول المتوقع (التاريخ النهائي الذي يتم احتسابه هو التاريخ المدرج في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'start_repayment_date_translated')" // Floating Point Proof
            }
          ]
        },
        {
          title: { en: 'Your signature', ar: 'توقيعك' },
          name: 'signature',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              type: 'html',
              title: '',
              html: {
                ar: your_signature.map(rtl).join(''),
                en: your_signature.map(p).join(''),
              },
            },
            { ...i_agree, name: 'responsible' },
            {
              type: 'text',
              inputType: 'text',
              name: 'sign_name',
              isRequired: true,
              title: {
                en: 'Guarantor Full Name',
                ar: 'اسم الكفيل الكامل (الاسم الرباعي)',
              },
            },
            {
              type: 'text',
              inputType: 'date',
              name: 'sign_date',
              isRequired: true,
              title: {
                en: 'Application Date',
                ar: 'تاريخ الطلب',
              },
            },
            {
              type: 'signaturepad',
              name: 'signature',
              isRequired: true,
              title: {
                en: 'Please enter your signature',
                ar: 'الرجاء إدخال توقيعك',
              },
            },
          ],
        },
      ],
    },
  ],
};
