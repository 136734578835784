import {insurance_html, text_direction} from '../../components/survey/review_fields';
import Translations from '../../config/translations.json';
import Config from '../../config';
import env from "../../env";

const required = env.ENDPOINT !== "local";

const { agree_fields } = Translations

/**
 * PLEASE NOTE, IMPORTANT!
 *
 * When changing labels, change "Signed agreement export"-feature
 * in Django Console AS WELL!
 */
const i_agree = {
  title: ' ',
  type: 'checkbox',
  name: 'agree',
  isRequired: true,
  choices: [
    {
      value:
        'Applicant has agreed to loan amount, loan term and total fees in the educational flow.',
      text: {
        en: 'I agree',
        ar: 'أنا أوافق',
      },
    },
  ],
  validators: [],
};

const map_agree_fields = agree_fields.map(
  (field, index) =>
    (field = {
      type: 'panel',
      elements: [
        {
          type: 'html',
          title: '',
          html: {
            ar: `<p style="margin-top:5px;" dir="${text_direction('ar')}">${field['ar']}</p>`,
            en: `<p style="margin-top:5px;" dir="${text_direction('en')}">${field['en']}</p>`,
          },
        },
        { ...i_agree, name: `bus_agree${index}` },
      ],
    })
);


export const agreement_mfw = (lt, prefix, locale) =>
  {

    return `<p>${prefix}</p>
      <h5 dir='${text_direction(locale)}'>برامج التأمين الميكروي</h5>
      ${insurance_html}
      <p>[x] I agree</p>
      <ol style="list-style: none;">
      <li>Loan Amount (JOD): ${lt.amount}</li>
      <li>Loan Terms (Months): ${lt.repayment_period}</li>
      <li>Expected Date to Receive the Loan (if granted): ${lt.start_loan_date_translated}</li>
      <li>Expected Monthly Administrative Fees (%): ${lt.monthly_administrative_fees * 100}%</li>
      <li>Expected Total Administrative Fees (JOD): ${lt.total_fees}</li>
      <li>Expected Monthly Installment including Fees (JOD): ${lt.monthly_installment}</li>
      <li>Expected First Installment Date: ${lt.start_repayment_date_translated}</li>
      </ol>
      ${agree_fields.map(agree => `<p dir='${text_direction(locale)}'>${agree['ar']}</p>\n<p>[x] I agree</p>\n`).join('')}
    `;
  };

export const agreement_rw = (lt, prefix) =>
  {

    return `<p>${prefix}</p>
      <h5>Agreement</h5>
      <p>[x] I agree</p>
      <ol style="list-style: none;">
      <li>Loan Amount (RWF): ${lt.amount}</li>
      <li>Loan Terms (Months): ${lt.repayment_period}</li>
      <li>Expected Date to Receive the Loan (if granted): ${lt.start_loan_date_translated}</li>
      <li>Expected Monthly Administrative Fees (%): ${lt.monthly_administrative_fees * 100}%</li>
      <li>Expected Total Administrative Fees (RWF): ${lt.total_fees}</li>
      <li>Expected Monthly Installment including Fees (RWF): ${lt.monthly_installment}</li>
      <li>Expected First Installment Date: ${lt.start_repayment_date_translated}</li>
      </ol>
    `;
  };

export const agreement = (lt, prefix, locale) => {
    return Config.deployment === 'rw'
    ? agreement_rw(lt, prefix)
    : agreement_mfw(lt, prefix, locale);

  }

export const review = {
  showProgressBar: 'top',
  showQuestionNumbers: 'off',
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: { en: 'Submit loan application', ar: 'إرسال طلب التمويل' },

  questionStartIndex: 1,
  requiredText: '',

  pages: [
    {
      title: {
        en: 'What kind of loan would you like to receive?',
        ar: 'مجموعة (8) ما نوع التمويل الذي تود الحصول عليه؟',
      },
      name: 'loan',
      elements: [
        {
          name: 'insurance',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              type: 'html',
              title: '',
              html: {
                en: `<div dir='${text_direction('en')}' class='sv_p_title'>برامج التأمين الميكروي</div>`,
                ar: `<div dir='${text_direction('ar')}' class='sv_p_title'>برامج التأمين الميكروي</div>`,
              },
            },
            {
              type: 'html',
              html: {
                en: insurance_html,
                ar: insurance_html,
              },
            },
            {
              title: ' ',
              type: 'checkbox',
              name: 'agreeMicroInsuranceProgramms',
              isRequired: true,
              choices: [
                {
                  value:
                    'Applicant has agreed to micro insurance programs in the review flow.',
                  text: {
                    en: 'I agree',
                    ar: 'أنا أوافق',
                  },
                },
              ],
              validators: [],
            },
          ],
        },
      ],
    },

    {
      title: {
        en: 'Review and sign your loan application',
        ar: 'قم بمراجعة وتوقيع طلب التمويل',
      },
      name: 'review',

      elements: [
        {
          name: 'total_overview',
          type: 'panel',
          visibleIf: '{amount} notempty and {repayment_period} notempty',
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: 'Loan Amount (JOD)',
                ar: 'قيمة القرض (دينار أردني)',
              },
              type: 'expression',
              expression: '{amount}', // Floating Point Proof
            },
            {
              title: {
                en: 'Loan Terms (Months)',
                ar: 'فترة السداد (أشهر)',
              },
              type: 'expression',
              expression: '{repayment_period}', // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Date to Receive the Loan (if granted)',
                ar: 'التاريخ المتوقع للحصول على القرض (في حال تم منحه)',
              },
              type: 'expression',
              name: 'start_loan_date_translated',
              expression: "getLoanTerm({loan_terms}, 'start_loan_date_translated')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Administrative Fees (%) (Final calculated numbers will be included in the contract)',
                ar: 'الرسوم الإدارية الشهرية المتوقعة (%) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "(getLoanTerm({loan_terms}, 'monthly_administrative_fees') * 100) + '%'" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Total Administrative Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'إجمالي الرسوم الإدارية المتوقعة (دينار أردني) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_fees')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Installment including Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'القسط الشهري المتوقع بما يشمل الرسوم (دينار) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_monthly')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected First Installment Date (Final calculated date will be included in the contract)',
                ar: 'تاريخ القسط الأول المتوقع (التاريخ النهائي الذي يتم احتسابه هو التاريخ المدرج في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'start_repayment_date_translated')" // Floating Point Proof
            },
          ],
        },
        {
          type: 'panel',
          elements: map_agree_fields,
        },
        {
          type: "panel",
          
          elements: [
            {
              type: "dropdown",
              name: "relationship_question",
              title: {
                en: `Are you or any of your family members up to rst degree in
                    minimum or any other persons working on your behalf or having
                    authorizations issued by you, occupying or have occupied a
                    prominent public position in the kingdom or in any foreign
                    country such as: prime minister, judge,military, high level
                    govermental oce, or was a prominent politician or prominent
                    person in any political party or senior executive in the corporations`,
                ar: `هل تشغل او شغلت او أي افراد عائلتك حتى الدرجة الأولى كحد أدنى أو أي اشخاص يعملون بالنيابة عمك أو
                      يملكون تفاويض صادرة عنك  وظيفة عامة بارزة في المملكة أو في دول
                      أجنبية مثل رئيس حكومة أو قاضي عسكري أو منصب حكومي رفيع
                      المستوى أو كان سياسي بارز او
                      شخصية بارزة في حزب سياسي او كبار التنفيذيين في الشركة المملوكة للدولة`
              },
              choices: [
                { value: true, text: { en: "Yes", ar: "نعم" } },
                { value: false, text: { en: "No", ar: "لا" } },
              ],
              isRequired: required,
            },
            {
              type: "text",
              name: "relationship_nature",
              title: {
                en: "Explain the nature of relation",
                ar: "العلاقة :"
              },
              isRequired: required,
            },
            {
              type: "text",
              name: "relationship_position",
              title: {
                en: "Position",
                ar: "المنصب :"
              },
              isRequired: required,
            },
            {
              type: "text",
              name: "relationship_person",
              title: {
                en: "In the event your answer is yes, who is sush political persone?",
                ar: "ي حال الأجابة نعم من هو الشخص السياسي ؟ "
              },
            }
          ]
        },

        {
          type: 'text',
          inputType: 'text',
          name: 'sign_name',
          isRequired: true,
          title: {
            en: 'Applicant Full Name',
            ar: 'اسم مقدّم الطلب الكامل (الاسم الرباعي)',
          },
        },

        {
          type: 'text',
          inputType: 'date',
          name: 'sign_date',
          isRequired: true,
          title: {
            en: 'Application Date',
            ar: 'تاريخ الطلب',
          },
        },

        {
          type: 'signaturepad',
          name: 'signature',
          isRequired: true,
          title: {
            en: 'Please enter your signature',
            ar: 'الرجاء التوقيع في المربع أدناه ',
          },
        },
      ],
    },
  ],
};
