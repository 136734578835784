const appConfiguration = {
  isLocal: (process.env['REACT_APP_DEPLOYMENT'] || 'local') === 'local',
  DEPLOYMENT: process.env['REACT_APP_DEPLOYMENT'] || 'local',
  DEPLOYMENT_NAME: process.env['REACT_APP_DEPLOYMENT_NAME'] || 'mfw',
  CLIENT_SECRET: process.env['REACT_APP_CLIENT_SECRET'] || '', // required for production builds. Define in bitbucket env vars.
   // ENDPOINT: 'local', //uncomment to test locally
  // ENDPOINT: 'local', //uncomment to test locally
};

export default appConfiguration;
