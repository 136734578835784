import {expressions} from "../../survey/validators";
import env from "../../env";

const required = env.ENDPOINT !== "local";

function picture(num, opts = {}) {
  return {
    title: {
      en: `picture ${num}`,
      ar: `${num} صورة:`
    },
    type: "file_uploader",
    accept: "image/*,application/pdf",
    name: `family_book${num}`,
    isRequired: false,
    ...opts
  };
}

const page_1 = {
  name: "personal",
  title: {
    en: "Applicant Information",
    ar: "معلومات مقدّم الطلب",
  },
  elements: [
    {
      title: { en: "Applicant Full Name ", ar: "اسم مقدّم الطلب الرباعي " },
      name: "last_name",
      type: "text",
      isRequired: required,
    },
    {
      name: "date_of_birth",
      type: "text",
      inputType: "date",
      isRequired: required,
      title: {
        en: "Date of Birth ",
        ar: "تاريخ الميلاد ",
      },
    },
    {
      title: { en: "What is your gender? ", ar: "ما هو جنسك؟ " },
      name: "gender",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "male", text: { en: "Male", ar: "رجل" } },
        { value: "female", text: { en: "Female", ar: "امرأة" } },
      ],
    },
    {
      title: {
        en: "How did you know MFW?",
        ar: "كيف عرفت عن صندوق المرأة",
      },
      name: "custom_text_1",
      type: "text",
      isRequired: required,
    },
    {
      title: { en: "Last Educational Level ", ar: "آخر مستوى تعليمي " },
      name: "education",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "unschooled", text: { en: "Unschooled", ar: "غير متعلم" } },
        {
          value: "primary_school",
          text: { en: "Primary school", ar: "مدرسة ابتدائية" },
        },
        {
          value: "secondary_school",
          text: { en: "Secondary education", ar: "تعليم ثانوي" },
        },
        {
          value: "bachelor_diploma",
          text: { en: "Bachelor diploma", ar: "شهادة بكالوريوس" },
        },
        {
          value: "master_diploma",
          text: { en: "Master diploma or higher", ar: "شهادة ماجستير أو أعلى" },
        },
      ],
    },
    {
      title: { en: "Major of Study ", ar: "تخصص الدراسة " },
      name: "education_field",
      type: "dropdown",
      isRequired: required,
      visibleIf: "{education} contains 'diploma'",
      choices: [
        {
          value: "arts",
          text: { en: "Humanities and arts", ar: "العلوم الإنسانية والفنون" },
        },
        {
          value: "social",
          text: { en: "Social sciences", ar: "العلوم الإجتماعية" },
        },
        {
          value: "business",
          text: { en: "Business/law", ar: "القانون/الأعمال" },
        },
        {
          value: "science",
          text: { en: "Science/math", ar: "العلوم/الرياضيات" },
        },
        { value: "engineering", text: { en: "Engineering", ar: "الهندسة" } },
        { value: "agriculture", text: { en: "Agriculture", ar: "الزراعة" } },
        {
          value: "health",
          text: { en: "Health and welfare", ar: "الصحة والخدمات الإجتماعية" },
        },
        { value: "other_diploma", text: { en: "Other degree", ar: " أخرى" } },
      ],
    },
  ],
};

const page_2 = {
  name: "identification",
  title: {
    en: "Identification Information",
    ar: "معلومات التعريف",
  },
  elements: [
    {
      name: "nationality",
      type: "dropdown",
      isRequired: required,
      storeOthersAsComment: false,
      hasOther: true,
      title: {
        en: "Nationality ",
        ar: "الجنسية ",
      },
      choices: [
        {
          value: "jordan",
          text: {
            en: "Jordanian",
            ar: "أردني",
          },
        },
        {
          value: "palestinian",
          text: {
            en: "Palestinian",
            ar: "فلسطيني",
          },
        },
        {
          value: "syrian",
          text: {
            en: "Syrian",
            ar: "سوري",
          },
        },
        {
          value: "egyptian",
          text: {
            en: "Egyptian",
            ar: "مصرية",
          },
        },
        {
          value: "iraqi",
          text: {
            en: "Iraqi",
            ar: "عراقية",
          },
        },
      ],
    },
    {
      title: {
        en: "What identification paper do you have?",
        ar: "نوع الوثيقة "
      },
      name: "id_type",
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "id_card",
          text: { en: "National ID Card", ar: "هوية مدنية" },
        },
        {
          value: "passport",
          text: { en: "Passport", ar: "جواز سفر" },
        },
      ],
    },
    {
      title: {
        en: "Place of Issue",
        ar: "مكان الإصدار",
      },
      name: "custom_text_2",
      type: "text",
      isRequired: required,
    },
    {
      type: "text",
      name: "id_number",
      title: {
        en: "National ID / Card ID / Document No. ",
        ar: "رقم الوثيقة ",
      },
      isRequired: required,
    },
    {
      title: { en: "Front Image (Attachment) ", ar: "الصورة الأمامية " },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later. ",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا ",
      },
      name: "id_file",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Back Image (Attachment) ",
        ar: "الصورة الخلفية ",
      },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later.",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
      },
      name: "id_file_back",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      name: "id_avatar",
      type: "panel",
      innerIndent: 1,
      isRequired: required,
      title: {
        en: "Please verify that this card belongs to you ",
        ar: "من فضلك قم بالتأكيد بأن هذه البطاقة تعود لك ",
      },
      elements: [
        {
          name: "id_avatar_desc",
          type: "html",
          description:
            "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
          html: {
            en: "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
            ar: "يرجى التحقق من معلومات التعريف المقدمة أعلاه عن طريق تحميل صورة لنفسك تحمل معلومة التعريف المقدمة.",
          },
        },
        {
          name: "id_avatar_desc_picture",
          type: "html",
          html: `<img alt="example" src='${require("../../assets/images/example_card.png")}' width='200'/>`,
        },
        {
          name: "id_file_with_avatar",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          title: {
            en: "Identification Picture ",
            ar: "صورة التعريف ",
          },
        },
      ],
    },
    {
      name: "id_question_mother",
      type: "text",
      isRequired: required,
      title: {
        en: "Mother's Name ",
        ar: "اسم الأم ",
      },
    },
    {
      name: "id_question_nickname",
      type: "text",
      isRequired: required,
      title: {
        en: "Surname ",
        ar: "الكنية ",
      },
    },
    {
      name: "id_question_birthplace",
      type: "text",
      isRequired: required,
      title: {
        en: "Place of Birth ",
        ar: "مكان الولادة ",
      },
    },
    {
      title: {
        en: "Civil Record No.",
        ar: "رقم القيد المدني ",
      },
      name: "custom_text_3",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Date of Issue",
        ar: "تاريخ الإصدار",
      },
      name: "custom_text_4",
      type: "text",
      inputType: "date",
      isRequired: required,
    },
    {
      title: {
        en: "Expiry Date",
        ar: "تاريخ الانتهاء",
      },
      name: "custom_text_5",
      type: "text",
      inputType: "date",
      isRequired: required,
    },
  ],
};

const page_3 = {
  title: {
    en: "Residence & Home Information",
    ar: "معلومات الإقامة والمنزل",
  },
  name: "home",
  elements: [
    {
      title: {
        en: "Client Full Address",
        ar: "العنوان بالتفصيل",
      },
      name: "home_addres_details",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Residency Status",
        ar: "الإقامة",
      },
      name: "custom_text_6",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "resident", text: { en: "Resident", ar: "مقيم" } },
        { value: "non-resident", text: { en: "Non-Resident", ar: "غير مقيم" } },
      ],
    },
    {
      title: {
        en: "Postal Code ",
        ar: "الرمز البريدي ",
      },
      name: "po_box_postal_code",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "PO Box ",
        ar: "صندوق البريد ",
      },
      name: "po_box_number",
      type: "text",
      isRequired: required,
    },
    {
      name: "phone_number",
      type: "phone",
      isRequired: required,
      validators: [
        expressions.phone_en("phone_number"),
        expressions.phone_length("phone_number"),
      ],
      title: {
        en: "Mobile No. 1 ",
        ar: "رقم الهاتف 1 ",
      },
    },
    {
      title: {
        en: "Landline No.",
        ar: "رقم الهاتف الأرضي ",
      },
      type: "phone",
      name: "custom_text_7",
      isRequired: required,
      validators: [
        expressions.phone_en("custom_text_7"),
        expressions.phone_length("custom_text_7"),
      ],
    },
    {
      title: {
        en: "Mailing Address Outside Jordan",
        ar: "العنوان البريدي خارج الأردن",
      },
      name: "custom_text_8",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Permanent Residence Address Outside Jordan",
        ar: "عنوان الإقامة خارج الأردن",
      },
      name: "custom_text_9",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Residence Status",
        ar: "وضع المنزل ",
      },
      name: "custom_text_10",
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "personally_owned",
          text: { en: "Personally Owned", ar: "ملك شخصي" },
        },
        {
          value: "family_owned",
          text: { en: "Family Owned", ar: "ملك للعائلة" },
        },
        { value: "rented", text: { en: "Rented", ar: "ايجار" } },
      ],
    },
    {
      name: "house_contract",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
      title: {
        en: "Rental or Ownership Agreement (Front Image) ",
        ar: "عقد الإيجار أو الملكية (الصورة الأمامية) ",
      },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later.",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
      },
    },
    {
      name: "house_contract_additional",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
      title: {
        en: "Rental or Ownership Agreement (Back Image) ",
        ar: "عقد الإيجار أو الملكية (الصورة الخلفية) ",
      },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later.",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
      },
    },
    {
      title: {
        en: "Residence in Jordan",
        ar: "الإقامة بالأردن ",
      },
      name: "custom_text_11",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Do you have another nationality?",
        ar: "هل تحمل جنيسة أخرى",
      },
      name: "custom_text_12",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "yes", text: { en: "Yes", ar: "نعم" } },
        { value: "no", text: { en: "No", ar: "لا" } },
      ],
    },
    {
      title: {
        en: "Do you own a car?",
        ar: "هل تملك سيارة ؟",
      },
      name: "custom_text_13",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "yes", text: { en: "Yes", ar: "نعم" } },
        { value: "no", text: { en: "No", ar: "لا" } },
      ],
    },
    {
      title: {
        en: "Do you own a home?",
        ar: "هل تملك منزلاً ؟",
      },
      name: "custom_text_14",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "yes", text: { en: "Yes", ar: "نعم" } },
        { value: "no", text: { en: "No", ar: "لا" } },
      ],
    },
    {
      title: {
        en: "Do you own land?",
        ar: "هل تملك أرضاً ؟",
      },
      name: "custom_text_15",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "yes", text: { en: "Yes", ar: "نعم" } },
        { value: "no", text: { en: "No", ar: "لا" } },
      ],
    },
    {
      title: {
        en: "Education",
        ar: "المؤهل العلمي ",
      },
      name: "education",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "primary", text: { en: "Primary", ar: "ابتدائي" } },
        { value: "preparatory", text: { en: "Preparatory", ar: "اعدادي" } },
        { value: "secondary", text: { en: "Secondary", ar: "ثانوي" } },
        { value: "college", text: { en: "College", ar: "كلية" } },
        { value: "diploma", text: { en: "Diploma", ar: "دبلوم" } },
        { value: "bachelor", text: { en: "Bachelor", ar: "بكالوريوس" } },
        { value: "masters", text: { en: "Masters", ar: "ماجستير" } },
        { value: "doctorate", text: { en: "Doctorate", ar: "دكتوراة" } },
      ],
    },
  ],
};

const page_4 = {
  title: {
    en: "Family Information",
    ar: "معلومات العائلة",
  },
  name: "family",
  elements: [
    {
      name: "marital",
      type: "dropdown",
      isRequired: required,
      title: {
        en: "What is your marital status? ",
        ar: "ما هي حالتك الاجتماعية؟ ",
      },
      choices: [
        {
          value: "single",
          text: {
            en: "Single",
            ar: "أعزب",
          },
        },
        {
          value: "married",
          text: {
            en: "Married",
            ar: "متزوج",
          },
        },
        {
          value: "divorced",
          text: {
            en: "Divorced",
            ar: "مطلق",
          },
        },
        {
          value: "widowed",
          text: {
            en: "Widowed",
            ar: "أرمل",
          },
        },
      ],
    },
    {
      title: {
        en: "Dependants No",
        ar: "عدد المعالين (أفراد الأسرة )",
      },
      name: "dependence_number",
      type: "text",
      inputType: "number",
      isRequired: required,
      validators: [
        {
          type: "numeric",
          minValue: 1,
          text: "الخيار الأدنى هو 1",
        },
        {
          type: "numeric",
          maxValue: 30,
          text: "الخيار الأعلى هو 30",
        },
      ],
    },
    {
      name: "full_name_spouse",
      type: "text",
      isRequired: required,
      title: {
        en: "Spouse Full Name ",
        ar: " اسم الزوج /ة : اسم الأول   / اسم الاب   / اسم الجد  / اسم العائلة",
      },
    },
    {
      title: {
        en: "Do you have a family booklet that you can upload? ",
        ar: "هل لديك دفتر عائلة يمكنك تحميله؟ ",
      },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later.",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
      },
      name: "panel_family_book",
      type: "panel",
      isRequired: required,
      innerIndent: 1,
      elements: [
        picture(1, { isRequired: required, name: "family_book" }),
        picture(2, { isRequired: required }),
        picture(3, { isRequired: required }),
        picture(4, { isRequired: required }),
        picture(5, { isRequired: false }),
        picture(6, { isRequired: false }),
      ],
    },
  ],
};

const page_5 = {
  title: {
    en: "Guarantors",
    ar: "الكفلاء",
  },
  name: "guarantor",
  elements: [
    {
      name: "panel_guarantor_1",
      type: "panel",
      innerIndent: 1,
      title: {
        en: "Guarantor 1",
        ar: "الكفيل 1",
      },
      elements: [
        {
          name: "guarantor_first_name",
          type: "text",
          isRequired: required,
          title: {
            en: "Guarantor Full Name",
            ar: "اسم الكفيل الرباعي ",
          },
        },
        {
          name: "custom_text_16",
          type: "text",
          isRequired: required,
          title: {
            en: "National ID Number",
            ar: "الرقم الوطني ",
          },
        },
        {
          name: "custom_text_17",
          type: "text",
          isRequired: required,
          title: {
            en: "Document No.",
            ar: "رقم الوثيقة ",
          },
        },
        {
          name: "guarantor_phone",
          type: "phone",
          isRequired: required,
          title: {
            en: "Guarantor Mobile No.",
            ar: "رقم الهاتف للكفيل",
          },
          validators: [
            expressions.same_phone_number("guarantor_phone"),
            expressions.phone_en("guarantor_phone"),
            expressions.phone_length("guarantor_phone"),
          ],
        },
        {
          name: "custom_text_18",
          type: "text",
          isRequired: required,
          title: {
            en: "Place Work",
            ar: "مكان العمل ",
          },
        },
        {
          name: "custom_float_1",
          type: "text",
          inputType: "number",
          isRequired: required,
          title: {
            en: "Income value",
            ar: "قيمة الدخل ",
          },
          validators: [expressions.amount_greater_than_zero("custom_float_1")],
        },
        {
          name: "custom_text_20",
          type: "text",
          isRequired: required,
          title: {
            en: "Address",
            ar: "العنوان ",
          },
        },
        {
          title: { en: "Front Image (Attachment)", ar: "الصورة الأمامية " },
          description: {
            en: "If you cannot find the document now, you can close this form and come back later. ",
            ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا ",
          },
          name: "file_1",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          title: {
            en: "Back Image (Attachment)",
            ar: "الصورة الخلفية ",
          },
          description: {
            en: "If you cannot find the document now, you can close this form and come back later.",
            ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
          },
          name: "file_2",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
      ],
    },
    {
      name: "panel_guarantor_2",
      type: "panel",
      innerIndent: 1,
      title: {
        en: "Guarantor 2",
        ar: "الكفيل 2",
      },
      elements: [
        {
          name: "guarantor_2_first_name",
          type: "text",
          isRequired: required,
          title: {
            en: "Guarantor Full Name",
            ar: "اسم الكفيل الرباعي ",
          },
        },
        {
          name: "custom_text_21",
          type: "text",
          isRequired: required,
          title: {
            en: "National ID Number",
            ar: "الرقم الوطني ",
          },
        },
        {
          name: "custom_text_22",
          type: "text",
          isRequired: required,
          title: {
            en: "Document No.",
            ar: "رقم الوثيقة ",
          },
        },
        {
          name: "guarantor_2_phone",
          type: "phone",
          isRequired: required,
          title: {
            en: "Guarantor Mobile No.",
            ar: "رقم الهاتف للكفيل)",
          },
          validators: [
            expressions.same_phone_number("guarantor_2_phone"),
            expressions.phone_en("guarantor_2_phone"),
            expressions.phone_length("guarantor_2_phone"),
          ],
        },
        {
          name: "custom_text_23",
          type: "text",
          isRequired: required,
          title: {
            en: "Place Work",
            ar: "مكان العمل ",
          },
        },
        {
          name: "custom_float_2",
          type: "text",
          inputType: "number",
          isRequired: required,
          title: {
            en: "Income value",
            ar: "قيمة الدخل ",
          },
          validators: [expressions.amount_greater_than_zero("custom_float_2")],
        },
        {
          name: "custom_text_25",
          type: "text",
          isRequired: required,
          title: {
            en: "Address",
            ar: "العنوان ",
          },
        },
        {
          title: { en: "Front Image (Attachment)", ar: "الصورة الأمامية " },
          description: {
            en: "If you cannot find the document now, you can close this form and come back later. ",
            ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا ",
          },
          name: "file_3",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          title: {
            en: "Back Image (Attachment)",
            ar: "الصورة الخلفية ",
          },
          description: {
            en: "If you cannot find the document now, you can close this form and come back later.",
            ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
          },
          name: "file_4",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
      ],
    },
  ],
};

const page_6 = {
  title: {
    en: "References",
    ar: "المعرفين",
  },
  name: "references",
  elements: [
    {
      type: "html",
      name: "reference_desc",
      html: {
        en: `<p>Please list 2 references who would like to give you a positive endorsement. For example: your (previous) employer, a colleague, teacher, advisor, etc.
          It can be anyone who can provide an insight into your work ethic. As long as the person is not your guarantor. </p>`,
        ar: `<p>قم رجاءاً بذكر معرفين اثنين ممن يمكن أن يزودانا بمعلومات تعريفية عنك. على سبيل المثال، مديرك السابق، أو زميلك في العمل، أو معلمك أو مرشدك… إلخ. ومن الممكن أن يكون المعرف شخص قادر على تزويدنا بلمحة عن أسلوبك في العمل. تأكد من أن يختلف المعرف عن  الكفيل </p>`,
      },
    },
    {
      title: {
        en: "Reference #1",
        ar: "المعرف الأول",
      },
      name: "panel_ref_1",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "What is the first and last name of your reference? ",
            ar: "الاسم الأول  للمعرف واسم عائلته ",
          },
          name: "ref1_name",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "What is the phone number of {ref1_name}? ",
            ar: "رقم هاتف المعرف الأول ",
          },
          name: "ref1_phone",
          type: "phone",
          isRequired: required,
          validators: [
            expressions.phone_en("ref1_phone"),
            expressions.phone_length("ref1_phone"),
          ],
        },
        {
          title: {
            en: "What is the relation of {ref1_name} to you? ",
            ar: "ما هي علاقة المعرف بك؟ {ref1_name} ",
          },
          description: {
            en: "e.g. your family, friend, boss",
            ar: "مثلا قريب لك، صديقك، مديرك",
          },
          name: "ref1_relation",
          type: "text",
          isRequired: required,
        },
      ],
    },
    {
      title: {
        en: "Reference #2",
        ar: "المعرف الثاني",
      },
      name: "panel_ref_2",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "What is the first and last name of your reference? ",
            ar: "الاسم الأول للمعرف واسم عائلته ",
          },
          name: "ref2_name",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "What is the phone number of {ref2_name}? ",
            ar: "رقم هاتف المعرف الثاني ",
          },
          name: "ref2_phone",
          type: "phone",
          isRequired: required,
          validators: [
            expressions.phone_en("ref2_phone"),
            expressions.phone_length("ref2_phone"),
          ],
        },
        {
          title: {
            en: "What is the relation of {ref2_name} to you? ",
            ar: "ما هي علاقة المعرف بك؟ {ref2_name} ",
          },
          description: {
            en: "e.g. your family, friend, boss",
            ar: "مثلا قريب لك، صديقك، مديرك",
          },
          name: "ref2_relation",
          type: "text",
          isRequired: required,
        },
      ],
    },
  ],
};

const page_7 = {
  title: {
    en: "Job Information",
    ar: "معلومات الوظيفة",
  },
  name: "job_details",
  elements: [
    {
      title: { en: "Type of employment", ar: "هل أنت " },
      name: "custom_text_26",
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "government_employee",
          text: { en: "Government employee", ar: "موظف حكومي " },
        },
        {
          value: "private_sector_employee",
          text: { en: "Private sector employee", ar: "موظف خاص " },
        },
        {
          value: "government_retired",
          text: { en: "Government retired", ar: "متقاعد حكومي " },
        },
        {
          value: "private_retired",
          text: { en: "Private retired", ar: "متقاعد خاص" },
        },
        { value: "military", text: { en: "Military", ar: "عسكري" } },
        {
          value: "retired_military",
          text: { en: "Retired military", ar: "متقاعد عسكري" },
        },
        {
          value: "daily_worker",
          text: { en: "Daily worker", ar: "عامل مياومة " },
        },
        {
          value: "owning_a_business_only",
          text: { en: "Owning a business only", ar: "يعمل بمشروعه الخاص" },
        },
        { value: "doesnt_work", text: { en: "Doesn't Work", ar: "بلا عمل" } },
      ],
    },
    {
      title: {
        en: "Profession",
        ar: "مكان العمل / أسم المؤسسة ",
      },
      name: "custom_text_27",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Place Work/Entity Name",
        ar: "اسم الشركة ",
      },
      name: "current_job_company",
      isRequired: required,
      type: "text",
    },
    {
      title: {
        en: "Work Phone No. ",
        ar: "رقم هاتف العمل ",
      },
      name: "current_job_phone",
      type: "phone",
      isRequired: required,
      validators: [
        expressions.phone_en("current_job_phone"),
        expressions.phone_length("current_job_phone"),
      ],
    },
    {
      title: {
        en: "Company Address ",
        ar: "عنوان الشركة ",
      },
      name: "current_job_address",
      isRequired: required,
      type: "comment",
    },
    {
      title: {
        en: "Do you have a current job? ",
        ar: "هل لديك وظيفة حالية؟ ",
      },
      name: "has_job",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: true, text: { en: "Yes", ar: "نعم" } },
        { value: false, text: { en: "No", ar: "لا" } },
      ],
    },
    {
      title: {
        en: "Building No.",
        ar: "رقم البناية ",
      },
      name: "custom_text_28",
      type: "text",
      isRequired: required,
    },
  ],
};

const page_8 = {
  title: {
    en: "Beneficiary",
    ar: "مستفيد",
  },
  name: "beneficiary",
  elements: [
    {
      title: {
        en: "Are you the actual beneficiary of the Funding?",
        ar: "هل انت المستفيد الحقيقي من التمويل ",
      },
      name: "custom_text_29",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "Yes", text: { en: "Yes", ar: "نعم" } },
        { value: "No", text: { en: "No", ar: "لا" } },
      ],
    },
    {
      name: "beneficiary_text",
      type: "html",
      html: {
        en: "If the answer is No - please fill the KYC form for the beneficiary",
        rw: 'في حال "لا" من هو المستفيد الحقيقي',
      },
    },
    {
      title: { en: "Front Image (Attachment)", ar: "الصورة الأمامية " },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later. ",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا ",
      },
      name: "file_5",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Back Image (Attachment)",
        ar: "الصورة الخلفية ",
      },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later.",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
      },
      name: "file_6",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
  ],
};

const page_9 = {
  name: "page_other_information",
  title: {
    en: "Other Information",
    ar: "معلومات أخرى",
  },
  elements: [
    {
      title: {
        en: "Do you have a wallet?",
        ar: "هل لديك محفظة الكترونية ",
      },
      name: "custom_text_30",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "Yes", text: { en: "Yes", ar: "نعم" } },
        { value: "No", text: { en: "No", ar: "لا" } },
      ],
    },
    {
      name: "id_question_bankaccount",
      type: "text",
      isRequired: required,
      title: {
        en: "Bank Account No. ",
        ar: "رقم الحساب البنكي ",
      },
    },
    {
      type: "panel",
      title: {
        en: "Please upload the last three months bank statements (at least) ",
        ar: "يرجى تحميل كشف حساب بنكي لآخر ثلاثة أشهر (على الأقل) ",
      },
      description: {
        en: "If you cannot find the document now, you can close this form and come back later.",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
      },
      elements: [1, 2, 3, 4].map((n) => ({
        title: {
          en: `Bank statement ${n} `,
          ar: "كشف حساب بنكي ",
        },
        name: `bank_statement_${n}`,
        type: "file_uploader",
        accept: "image/*,application/pdf",
        isRequired: required,
      })),
    },
    {
      name: "mcf_incomes",
      type: "matrixdynamic",
      isRequired: required,
      minRowCount: 1,
      maxRowCount: 3,
      rowCount: 1,
      addRowText: {
        en: "Add",
        ar: "إضافة",
      },
      titleLocation: "none",
      columns: [
        {
          title: {
            en: "Amount (JOD)",
            ar: "المبلغ (دينار أردني)",
          },
          name: "mcf_income_amount",
          isRequired: required,
          cellType: "text",
          inputType: "number",
          validators: [
            expressions.amount_greater_than_zero("mcf_income_amount"),
          ],
        },
        {
          title: {
            en: "Item",
            ar: "البند",
          },
          name: "mcf_income_source",
          cellType: "dropdown",
          inputType: "string",
          isRequired: required,
          choices: [
            { value: "basic", text: { en: "Basic", ar: " قيمة الالتزام " } },
            { value: "other", text: { en: "Other", ar: " مصدر الدخل   " } },
          ],
        },
      ],
    },
    {
      title: {
        en: "Liabilities Details",
        ar: "تفاصيل الالتزامات",
      },
      name: "liabilities",
      valueName: "liabilities",
      type: "matrixdynamic",
      minRowCount: 1,
      rowCount: 1,
      addRowText: { en: "Add", ar: "إضافة" },
      columns: [
        {
          title: {
            en: "Amount (JOD)",
            ar: " قيمة الالتزام ",
          },
          name: "liability_amount",
          isRequired: required,
          cellType: "text",
          inputType: "number",
          validators: [
            expressions.amount_greater_than_zero("liability_amount"),
          ],
        },
        {
          title: { en: "Liability", ar: " جهة الالتزام  " },
          name: "liability",
          isRequired: required,
          cellType: "text",
          inputType: "string",
        },
      ],
    },
  ],
};

const business_page = {
  name: "business_product_privacy",
  title: {
    en: "Business product privacy",
    ar: "خصوصية منتج الاعمال  "
  },
  elements: [
    {
      title: {
        en: "Project Name",
        ar: "اسم المشروع "
      },
      name: "custom_text_31",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Project Address",
        ar: "عنوان المشروع"
      },
      name: "custom_text_32",
      type: "text",
      isRequired: required,
    },
    {
      title: { en: "Loan Amount (JOD) ", ar: "قيمة القرض (دينار أردني) " },
      description: { en: "In JOD", ar: "بالدينار الأردني" },
      name: "amount",
      type: "nouislider",
      step: 1,
      rangeMin: 500,
      rangeMaxStart: 10000,
      maxStep: 10000,
      rangeMax: 75000,
      isRequired: required
    },
    {
      title: {
        en: "Project Duration (in years)",
        ar: "عمر المشروع (بالسنوات): "
      },
      name: "custom_integer_1",
      type: "text",
      inputType: "number",
      isRequired: required,
    },
    {
      title: {
        en: "Project sector",
        ar: "قطاع المشروع "
      },
      name: "custom_text_33",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "tourism", text: { en: "Tourism", ar: "سياحي" } },
        { value: "agricultural", text: { en: "Agricultural", ar: "زراعي" } },
        { value: "industrial", text: { en: "Industrial", ar: "صناعي" } },
        { value: "services", text: { en: "Services", ar: "خدمي" } },
        { value: "commercial", text: { en: "Commercial", ar: "تجاري" } },
      ],
    },
    {
      title: {
        en: "Number of Employees",
        ar: "عدد الموظفين:"
      },
      name: "custom_integer_2",
      type: "text",
      inputType: "number",
      isRequired: required,
    },
    {
      title: {
        en: "Project state",
        ar: "وضع التسجيل: "
      },
      name: "custom_text_34",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "at_home", text: { en: "At home", ar: "منزلي" } },
        { value: "independent_undocumented", text: { en: "Independent, undocumented", ar: "مستقل غير مسجل" } },
        { value: "certified_independent", text: { en: "Certified independent", ar: "مستقل مسجل" } },
      ]
    },
    {
      title: {
        en: "The purpose of funding:",
        ar: "الغاية من التمويل:  "
      },
      name: "custom_text_35",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "operating_expenses", text: { en: "Operating expenses", ar: "مصاريف تشغيلية" } },
        { value: "payment_of_suppliers", text: { en: "Payment of suppliers", ar: "سداد ذمم للموردين" } },
        { value: "maintenance", text: { en: "Maintenance", ar: "ديكور واعمال صيانة" } },
        { value: "purchase_of_fixed_assets", text: { en: "Purchase of fixed assets", ar: "شراء اصول ثابته" } },
        { value: "buying_goods", text: { en: "Buying goods", ar: "شراء بضاعة" } },
      ]
    }
  ]
}

const education_page = {
  name: "privacy_of_the_education",
  title: {
    en: "Privacy of the educational product",
    ar: "منتج تعليمي "
  },
  elements: [
    {
      title: { en: "Loan Amount (JOD) ", ar: "مبلغ التمويل" },
      description: { en: "In JOD", ar: "بالدينار الأردني" },
      name: "amount",
      type: "nouislider",
      step: 1,
      rangeMin: 500,
      rangeMaxStart: 10000,
      maxStep: 10000,
      rangeMax: 75000,
      isRequired: required
    },
    {
      title: {
        en: "University Name",
        ar: "اسم الجامعة:"
      },
      name: "custom_text_36",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Faculty",
        ar: "الكلية:"
      },
      name: "custom_text_37",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Section",
        ar: "التخصص:"
      },
      name: "custom_text_38",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Academic Hours (Completed) ",
        ar: "عدد الساعات المتبقية:"
      },
      name: "academic_hours_completed",
      type: "text",
      inputType: "number",
      isRequired: required,
    },
    {
      name: "custom_float_3",
      type: "text",
      inputType: "number",
      isRequired: required,
      title: {
        en: "The price of an hour of study",
        ar: "سعر الساعة الدراسية:"
      },
    },
    {
      title: {
        en: "GPA (Grade Point Average) ",
        ar: "المعدل التراكمي:"
      },
      name: "academic_gpa",
      type: "text",
      isRequired: required
    },
    {
      title: {
        en: "Type of study",
        ar: "نوع الدراسة:"
      },
      name: "custom_text_39",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "regular", text: { en: "Regular", ar: "نظامي" } },
        { value: "private", text: { en: "Private", ar: "موازي" } },
        { value: "parallel", text: { en: "Parallel", ar: "خاص" } },
        { value: "international", text: { en: "International", ar: "دولي" } },
      ]
    },
    {
      title: {
        en: "The purpose of the loan",
        ar: "الغاية من التمويل:"
      },
      name: "custom_text_40",
      type: "dropdown",
      isRequired: required,
      choices: [
        { value: "diploma", text: { en: "Diploma", ar: "دبلوم" } },
        { value: "bachelor", text: { en: "Bachelor", ar: "بكالوريس" } },
        { value: "masters", text: { en: "Masters", ar: "ماجستير" } },
        { value: "doctorate", text: { en: "Doctorate", ar: "دكتوراة" } },
        { value: "educational_vocational_course", text: { en: "Educational/Vocational Course", ar: "دورة تعليمية/مهنية" } },
        { value: "school", text: { en: "School", ar: "مدارس" } },
      ]
    }
  ]
}

export const business_intake_mfw = [
  // Page 1: APPLICANT INFORMATION
  page_1,
  // Page 2: IDENTIFICATION INFORMATION
  page_2,
  // Page 3: RESIDENCE & HOME INFORMATION
  page_3,
  // Page 4: FAMILY INFORMATION
  page_4,
  // Page 5: GUARANTORS
  page_5,
  // Page 6: REFERENCES
  page_6,
  // Page 7: JOB INFORMATION
  page_7,
  // Page 8: BENEFICIARY
  page_8,
  // Page 9: OTHER INFORMATION
  page_9,
  // Page 10: BUSINESS
  business_page
]

export const education_intake_mfw = [
  // Page 1: APPLICANT INFORMATION
  page_1,
  // Page 2: IDENTIFICATION INFORMATION
  page_2,
  // Page 3: RESIDENCE & HOME INFORMATION
  page_3,
  // Page 4: FAMILY INFORMATION
  page_4,
  // Page 5: GUARANTORS
  page_5,
  // Page 6: REFERENCES
  page_6,
  // Page 7: JOB INFORMATION
  page_7,
  // Page 8: BENEFICIARY
  page_8,
  // Page 9: OTHER INFORMATION
  page_9,
  // Page 10: EDUCATION
  education_page
]