import React from 'react';
import {connect} from 'react-redux';

import {deviceActions, loanActions} from "../../store/store";

import './application.container.scss';
import {ws_next} from "../../components/icon/icon";
import {colors} from "../../theme/theme";
import {translate} from "../../lib/intl";
import HelpComponent from "../../components/help/help";
import {Button} from "../../components/button/button";
import {Footer} from "../../components/footer/footer";

import Config from "../../config";

let img_lookup = {
  signup_done: require('../../assets/images/stages/signup_done.png'),
  intake_next: require('../../assets/images/stages/intake_next.png'),
  intake_done: require('../../assets/images/stages/intake_done.png'),
  assessment_todo: require('../../assets/images/stages/assessment_todo.png'),
  assessment_next: require('../../assets/images/stages/assessment_next.png'),
  assessment_done: require('../../assets/images/stages/assessment_done.png'),
  review_todo: require('../../assets/images/stages/review_todo.png'),
  review_next: require('../../assets/images/stages/review_next.png'),
};

class ApplicationContainer extends React.PureComponent {

  constructor(props) {
    super(props);

    // current state required for translation file
    let current_state = props.loan.state.replace('applying_','');

    // correct current state in case it is redo
    if (props.loan.state === 'applying_intake' && props.loan.assessment.is_completed) {
      current_state = 'intake_redo'
    }

    console.log('current state', current_state);
    this.state = {

      // stages depend on completed intake or assessment forms
      current_stage: current_state,

      signup: 'done',
      intake: props.loan.state === 'applying_intake' ? 'next' : 'done',
      assessment: props.loan.assessment.is_completed ? 'done' : (props.loan.state === 'applying_assessment' ? 'next' : 'todo'),
      review: props.loan.state === 'applying_review' ? 'next' : 'todo',

      help_count: 0,

      loan_purpose: this.props.loan.loan_purpose,

      hasToGoBack: this.props.loan.intake.has_skipped_any_page && current_state === 'checking_intake',
      checkingIntake: current_state === 'checking_intake'
    };
  }
  

  colors = {done: colors.brand.secondary, next: colors.brand.primary, todo: colors.coolGray.c2};

  render_stage = (name, state, title, time, onClick) => (
    <div className={`stage ${state === 'next' ? 'clickable' : ''}`} onClick={state === 'next' ? onClick : () => {}}>

      <img src={img_lookup[`${name}_${state}`]} alt={name} className={`circle ${state === 'next' ? 'bigger' : 'smaller'}`}/>

      <div className={`descriptor ${state}`}>
        <p className={`small heavy center ${state === 'todo' ? 'cool_c4' : ''}`}>{title}</p>
        <p className={`small center ${state === 'todo' ? 'cool_c4' : ''}`}>{time}</p>
      </div>
    </div>
  );

  render = ({device, setPage, loan, toStart, checkedIntake} = this.props) => {
    console.log(this.props)
    return (
      <div className="applicationContainer">

        <HelpComponent onClose={() => {}} onOpen={() => {this.setState({help_count: this.state.help_count + 1})}} type='home' locale={device.locale}/>

        <div className='header'>

          {this.state.help_count === 0 && (
            <div  className={`bounce help_arrow_${device.locale === 'ar' ? 'ar' : 'en'}`}>
              <img src={require('./res/arrow.png')} alt='arrow' width='35'/>
            </div>
          )}

          <h3 className='primary center bigSpacer'>{translate(`application.${this.state.current_stage}_title`, {first_name: loan.intake.first_name})}</h3>
          <p className='center'>{translate(`application.${this.state.current_stage}_desc`,{intake_steps:Config.intake_steps ? Config.intake_steps : 3})}</p>
          <p className='center'>{translate(`application.${this.state.current_stage}_desc2`)}</p>

        </div>

        <div className='selector'>

          {this.render_stage('signup', this.state.signup, translate('application.signup'),  Config.has_no_container_time ? undefined : translate('general.minutes', {minutes: 1}), null)}
          <div className={`line ${this.state.signup}`}/>

          {this.render_stage('intake', this.state.intake, translate('application.intake'),  Config.has_no_container_time ? undefined : translate('general.minutes', {minutes: 20}), () => setPage('applying_intake'))}
          <div className={Config.has_no_review ? undefined : `line ${this.state.intake}`}/>

          {/* MON-421: requested the "About you" questions to be hidden  */}
          {/* {this.state.loan_purpose !== 'education' ? this.render_stage('assessment', this.state.assessment, translate('application.assessment'),  translate('general.minutes', {minutes: 60}), () => setPage('applying_assessment')) : ''}
          {this.state.loan_purpose !== 'education' ? <div className={`line ${this.state.assessment}`}/> : ''} */}

          {Config.has_no_review ? undefined : this.render_stage('review', this.state.review, translate('application.review'),  translate('general.minutes', {minutes: 5}), () => setPage('applying_review')) }
        </div>

        <div className='continueButton'>
          {!this.state.hasToGoBack && this.state.checkingIntake && <Button
            title={translate('general.continue')}
            backgroundColor={colors.brand.primary}
            icon={ws_next}
            onPress={() => checkedIntake(loan.uuid, loan.loan_purpose)}
          />}
          {this.state.current_stage !== 'checking_intake' && <Button
            title={translate('general.continue')}
            backgroundColor={colors.brand.primary}
            icon={ws_next}
            onPress={() => setPage(loan.state)}
          />}
        </div>
        <div className='backButton'>
          {this.state.hasToGoBack && <div className="header">
            <p className='center backButtonDescription'>{translate('general.checking_intake_has_to_go_back')}</p>
          </div>}
          
          
          {Config.user_states.includes('choose_intake_flow') && !Config.has_no_review_application && <Button
            title={translate('application.review_application')}
            backgroundColor={colors.brand.primary}
            onPress={() => {toStart(loan.uuid, loan.state, loan.purpose, loan.intake); setPage('choose_intake_flow')}} // FOR MFW REMOVE set page
          />}
        </div>

        <Footer/>

      </div>
    )};
}


const mapStateToProps = (state) => {
  return {
    device: state.device,
    loan: state.loan
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocale: (locale) => dispatch(deviceActions.setLocale(locale)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
    setFlow: (uuid, state, purpose) => dispatch(loanActions.setFlow(uuid, state, purpose)),
    toStart: (uuid, state, purpose, intake) => dispatch(loanActions.toStart(uuid, state, purpose, intake)),
    checkedIntake: (uuid, purpose) => dispatch(loanActions.checkedIntake(uuid, purpose))
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationContainer);

export {connectedContainer as ApplicationContainer};
