import moment from "moment";

function showErrorAndCleanCURPRelatedFields(firstName, surname, secondSurname, birthPlace, gender, dateOfBirth) {
    document.querySelector('#curp_validation_error').style.display = 'block';

    document.querySelector(firstName).value = "";
    document.querySelector(surname).value = "";
    document.querySelector(secondSurname).value = "";
    document.querySelector(birthPlace).value = "";
    document.querySelector(gender).value = "";
    document.querySelector(dateOfBirth).value = "";
}

// https://chuckconway.com/changing-a-react-input-value-from-vanilla-javascript/
export function setNativeValue(element, value) {
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", {target: element, bubbles: true});
    // React 15
    event.simulated = true;
    // React 16
    let tracker = element._valueTracker;
    if (tracker) {
        tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
}

function createCurpButton() {
    if (!document.querySelector('#validar_curp')) {
        const curpButton = document.createElement("input");
        curpButton.type = "button";
        curpButton.value = "Validar";
        curpButton.id = "validar_curp";

        // add button to page
        let beforeBtn = document.querySelector('.curp-button');
        beforeBtn.parentNode.insertBefore(curpButton, beforeBtn);
        return curpButton;
    }
}

function disableCURPInputs(firstName, surname, secondSurname, birthPlace, gender, dateOfBirth) {
    document.querySelector(firstName).disabled = true;
    document.querySelector(surname).disabled = true;
    document.querySelector(secondSurname).disabled = true;
    document.querySelector(birthPlace).disabled = true;
    document.querySelector(gender).disabled = true;
    document.querySelector(dateOfBirth).disabled = true;
}

export const addCURPButton = (apiToken, apiBaseURL, curpInputSelector, firstNameInputSelector, lastNameInputSelector,
                              motherNameInputSelector, birthPlaceInputSelector, genderInputSelector,
                              dateofBirthInputSelector) => () => {

    let beforeBtn = document.querySelector('.curp-button');

    if (beforeBtn) { // we do this because it only exists in some pages
        // disable inputs because SurveyJS doesn't validate required fields if they are readonly
        disableCURPInputs(firstNameInputSelector, lastNameInputSelector, motherNameInputSelector, birthPlaceInputSelector,
            genderInputSelector, dateofBirthInputSelector);

        const curpButton = createCurpButton();
        if (curpButton) {
            curpButton.onclick = () => {
                document.querySelector('#curp_validation_error').style.display = 'none';
                document.querySelector('#curp_format_error').style.display = 'none';
                let curp = document.querySelector(curpInputSelector).value;
                let curp_format = /^[A-Za-z]{4}\d{6}([A-Za-z]{6}\d{2}|[A-Za-z]{7}\d)$/;
                if (!curp_format.test(curp)) {
                    document.querySelector('#curp_format_error').style.display = 'block';
                } else {
                    // apiBaseURL = "http://localhost:3001"
                    fetch(apiBaseURL + '/api/PersonaFisica/ConsultarCurp?Curp=' + curp, {
                        method: 'GET', headers: {
                            'Authorization': 'Bearer ' + apiToken,
                            'Content-Type': 'application/json', 'Accept': 'application/json'
                        }
                    })
                        .then(response => {
                            if (response.status === 200) {
                                let jsonPromise = response.json();
                                jsonPromise.then(function (data) {
                                    setNativeValue(document.querySelector(firstNameInputSelector), data.nombres);
                                    setNativeValue(document.querySelector(lastNameInputSelector), data.apellidoPaterno);
                                    setNativeValue(document.querySelector(motherNameInputSelector), data.aperllidoMaterno);
                                    setNativeValue(document.querySelector(birthPlaceInputSelector), data.entidadNacimiento.nombre);
                                    setNativeValue(document.querySelector(genderInputSelector), data.sexo);
                                    let formatted_date = moment(data.fechaNacimiento, "DD/MM/YYYY").format('YYYY-MM-DD');
                                    setNativeValue(document.querySelector(dateofBirthInputSelector), formatted_date);
                                });
                            } else {
                                showErrorAndCleanCURPRelatedFields(firstNameInputSelector, lastNameInputSelector, motherNameInputSelector,
                                    birthPlaceInputSelector, genderInputSelector, dateofBirthInputSelector);
                            }

                        })
                        .catch(error => {
                            console.log(error);
                            showErrorAndCleanCURPRelatedFields(firstNameInputSelector, lastNameInputSelector, motherNameInputSelector,
                                birthPlaceInputSelector, genderInputSelector, dateofBirthInputSelector);

                        });
                }
            }
        }
    }
}
